<template>
  <div id="theHeader">
    <div class="logoWrapper">
      <img @click="goHome()" class="logo" alt="logo" src="../assets/logo.png" />
    </div>
    <div class="pcMenu flex justify-space-around">
      <div
        v-for="item in menu"
        :key="item.label"
        class="menuItem"
        @click="clickMenu(item)"
        v-wave
        :class="{ activeMenu: checkActiveMenu(item) }"
      >
        {{ item.label }}
      </div>
      <div class="menuItem" @click="goInstagram()" v-wave>
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </div>
      <div
        class="menuItem"
        @click="showSearchModal()"
        :class="{ activeMenu: checkActiveMenu({ link: 'Search' }) }"
        v-wave
      >
        <font-awesome-icon :icon="['fa', 'search']" />
      </div>
    </div>
    <modal name="searchModal" :width="400" :height="80">
      <div class="searchModalWrapper">
        <form @submit.prevent="search()">
          <!-- <InputSearch :searchText="searchText" /> -->
          <b-field>
            <b-input
              v-model="searchText"
              placeholder="Search..."
              type="search"
              icon="magnify"
              icon-clickable
              @icon-click="search()"
            >
            </b-input>
          </b-field>
        </form>
      </div>
    </modal>

    <!-- モバイル関連 -->
    <div class="spMenuIcon" @click="activeSpMenu = true" v-wave>
      <font-awesome-icon :icon="['fa', 'bars']" />
    </div>
    <div class="spTitle">
      {{ getPageTitle }}
    </div>
    <div class="spMenu" :class="{ activeSpMenu: activeSpMenu }">
      <div class="closeMobileMenu" @click="activeSpMenu = false" v-wave>
        <font-awesome-icon :icon="['fa', 'times']" />
      </div>
      <div
        v-for="item in menu"
        :key="item.label"
        class="menuItemSp"
        @click="clickMenu(item)"
      >
        {{ item.label }}
      </div>
      <div class="menuItemSp" @click="goInstagram()">
        <font-awesome-icon :icon="['fab', 'instagram']" />
      </div>
      <div class="menuItemSp" @click="showSearchModal()">
        <font-awesome-icon :icon="['fa', 'search']" />
      </div>
      <!-- <div class="menuItemSp">
        <form @submit.prevent="search()">
          <InputSearch :searchText="searchText" />
        </form>
      </div> -->
    </div>
  </div>
</template>

<script>
//import InputSearch from "@/components/atoms/InputSearch.vue";
export default {
  name: "TheHeader",
  components: {
    //InputSearch
  },
  data() {
    return {
      searchText: "",
      menu: [
        {
          label: "Home",
          link: "Home",
          isActive: false
        },
        {
          label: "News",
          link: "News",
          isActive: false
        },
        {
          label: "Contact",
          link: "Contact",
          isActive: false
        }
      ],
      activeSpMenu: false
    };
  },
  watch: {
    $route() {
      this.activeSpMenu = false;
    }
  },
  computed: {
    getPageTitle() {
      const routeName = this.$route.name;
      return routeName;
    }
  },
  methods: {
    goHome() {
      const routeName = this.$route.name;
      if (routeName === "Home") return;
      this.$router.push({ name: "Home" });
    },
    clickMenu(item) {
      const routeName = this.$route.name;
      if (item.link === routeName) return;
      this.$router.push({ name: item.link });
    },
    showSearchModal() {
      // 一旦はモーダルではなく検索ページに遷移する。
      const routeName = this.$route.name;
      if ("Search" === routeName) return;
      this.$router.push({ name: "Search" });
      // this.$modal.show("searchModal");
    },
    goInstagram() {
      const url = "https://www.instagram.com/falcemagazine/";
      window.open(url, "_blank");
    },
    search() {
      this.$router.push({ name: "Search" });
    },
    checkActiveMenu(item) {
      const routeName = this.$route.name;
      return item.link === routeName;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styls/variables.scss";
#theHeader {
  position: relative;
  font-size: 1.2rem;
  font-family: $font-menu;
  font-weight: 600;
  min-height: 48px;
  margin: setSingleMargin(2) 0 0 0;
  @include lessThan(xs) {
    margin-bottom: setSingleMargin(2);
  }
  @include transition(0.7s);
  .logoWrapper {
    width: 100%;
    > img {
      height: 50px;
      cursor: pointer;
    }
    @include lessThan(xs) {
      width: 90%;
      margin: 0 auto;
      > img {
        height: 40px;
      }
    }
  }
  .pcMenu {
    max-width: $content-medium-width;
    margin: 0 auto;
    padding: setVerticalMargin(2);
    @include lessThan(xs) {
      display: none;
    }
    .menuItem {
      cursor: pointer;
      padding: setHorizontalMargin(1);
      &.activeMenu {
        border-bottom: 2px solid $main-color;
      }
    }
  }
  .spMenuIcon {
    font-size: 1.5rem;
    display: none;
    color: $main-color;
    position: absolute;
    top: -10px;
    right: 15px;
    padding: setSingleMargin(0.5);
    @include lessThan(xs) {
      display: block;
    }
  }
  .spTitle {
    font-size: 1.2rem;
    margin: setVerticalMargin(1);
    display: none;
    @include lessThan(xs) {
      display: block;
    }
  }
  .spMenu {
    @include transition(0.3s);
    padding: setSingleMargin(4) setSingleMargin(1) setSingleMargin(1)
      setSingleMargin(1);
    position: relative;
    box-sizing: border-box;
    z-index: 2;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    &.activeSpMenu {
      right: 0;
    }
    .closeMobileMenu {
      padding: setSingleMargin(1);
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 1.5rem;
    }
    .menuItemSp {
      margin: setVerticalMargin(1);
    }
  }
}
.searchModalWrapper {
  padding: setSingleMargin(1);
}
</style>

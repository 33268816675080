<template>
  <div class="largeContainer">
    <slot>
      No slot
    </slot>
  </div>
</template>
<script>
export default {
  name: "largeContainer"
};
</script>
<style lang="scss" scoped>
@import "@/styls/variables.scss";
.largeContainer {
  width: auto;
  margin: 0 auto;
  position: relative;
  max-width: $content-width;
  margin: 0 auto;
}
</style>

<template>
  <div id="app">
    <TheHeader />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <TheFooter />
    <div
      id="btnGoUp"
      v-scroll-to="'#app'"
      :class="{ show: showBtnGoTop }"
      v-wave
    >
      <font-awesome-icon :icon="['fa', 'chevron-up']" />
    </div>
    <LoadingCover />
  </div>
</template>
<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";
import LoadingCover from "@/components/atoms/LoadingCover.vue";
import { mapActions } from "vuex";
export default {
  name: "baseApp",
  components: {
    TheHeader,
    TheFooter,
    LoadingCover
  },
  data() {
    return {
      scrollY: 0
    };
  },
  computed: {
    showBtnGoTop() {
      if (this.scrollY > 10) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollEvent);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollEvent);
  },
  methods: {
    ...mapActions({
      showLoading: "Utility/showLoading",
      hideLoading: "Utility/hideLoading"
    }),
    scrollEvent() {
      this.scrollY = window.scrollY;
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styls/variables.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $font-base-color;
  width: 100%;
  #btnGoUp {
    background: rgba(230, 230, 230, 0.7);
    position: fixed;
    bottom: -50px;
    right: 12px;
    border-radius: 6px;
    padding: 12px;
    @include transition(0.25s);
    cursor: pointer;
    transform-origin: center;
    font-size: 1.5rem;
    &:hover {
      transform: scale(1.1, 1.1);
    }
    &.show {
      bottom: 12px;
    }
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<template>
  <div class="swiperSlider">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(slide, idx) in sliders" :key="idx">
        <img class="sweiperImage" :src="slide.imgSrc" alt="ホームイメージ" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "swiperSlider",
  props: {
    sliders: {
      type: Array
    }
  },
  data() {
    return {
      swiperOptions: {
        speed: 700,
        loop: true,
        autoplay: {
          delay: 6000
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true
        }
        // Some Swiper option/callback...
      }
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
.swiperSlider {
  .sweiperImage {
    width: 100%;
    max-width: 800px;
  }
}
</style>

<style lang="scss">
.swiperSlider {
  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 16px;
      height: 16px;
      background-color: #707070;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background-color: #fff;
      border: 1px solid #707070;
      opacity: 1;
    }
  }
}
</style>

<template>
  <div class="inputSearch">
    <input type="text" v-model="innerSearchText" placeholder="Search" />
    <font-awesome-icon
      @click="onClick()"
      class="searchIcon"
      :icon="['fa', 'search']"
    />
  </div>
</template>

<script>
export default {
  name: "inputSearch",
  props: {
    searchText: {
      type: String,
      default: ""
    }
  },
  data() {
    return {};
  },
  computed: {
    innerSearchText: {
      get() {
        return this.searchText;
      },
      set(value) {
        this.$emit("change", value);
      }
    }
  },
  methods: {
    onClick() {
      this.$emit("iconClick");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
.inputSearch {
  position: relative;
  width: 100%;
}
.inputSearch input[type="text"] {
  font: 15px/24px sans-serif;
  box-sizing: border-box;
  width: 100%;
  margin: 8px 0;
  padding: 0.3em;
  transition: 0.3s;
  border: 1px solid #1b2538;
  border-radius: 4px;
  outline: none;
}
.inputSearch input[type="text"]:focus {
  border-color: $active-color;
}
.inputSearch input[type="text"] {
  padding-left: 40px;
}
.inputSearch svg {
  box-sizing: content-box;
  position: absolute;
  top: 8px;
  left: 0;
  padding: 9px 8px;
  transition: 0.3s;
  color: #aaaaaa;
}
.inputSearch input[type="text"]:focus + i {
  color: $active-color;
}
</style>

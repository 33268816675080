import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styls/global.scss";
import "flex.box/src/flexbox.css";
import VueScrollTo from "vue-scrollto";
import VueAwesomeSwiper from "vue-awesome-swiper";
// import "swiper/swiper-bundle.css";
import "swiper/css/swiper.css";
import VModal from "vue-js-modal";
import VWave from "v-wave";
import VueGtag from "vue-gtag";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faTimes,
  faBars,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";

import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import Buefy from "buefy";
import "buefy/dist/buefy.css";

import "./assets/fonts/style.scss";
/**
 * Orignal component
 */
import Button from "./components/atoms/Button.vue";
import InputSearch from "./components/atoms/InputSearch.vue";
import LargeContainer from "@/components/containers/LargeContainer.vue";
import MediumContainer from "@/components/containers/MediumContainer.vue";
library.add(faInstagram);
library.add(faSearch);
library.add(faTimes);
library.add(faBars);
library.add(faChevronUp);

Vue.component("font-awesome-icon", FontAwesomeIcon);
/**
 * plugin
 */
Vue.use(VueScrollTo);
Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.use(VModal);
Vue.use(VWave, {
  color: "currentColor",
  easing: "ease-out",
  duration: 0.4,
  initialOpacity: 0.2,
  finalOpacity: 0.1,
  cancellationPeriod: 75
});
Vue.use(VueGtag, {
  config: { id: "G-B30C1NT2DD" }
});

// buefy関連
Vue.use(Buefy, {
  defaultIconPack: "fa"
});

/**
 * Orignal component
 */
Vue.component("Button", Button);
Vue.component("InputSearch", InputSearch);
Vue.component("LargeContainer", LargeContainer);
Vue.component("MediumContainer", MediumContainer);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

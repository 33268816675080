<template>
  <div id="home">
    <LargeContainer>
      <div class="homeSlider">
        <swiperSlider :sliders="topSlider.data" />
      </div>
    </LargeContainer>
    <div class="homeTiles">
      <tileLayoutCards
        :posts="posts.data"
        @completeImageLoad="finImageLoad()"
      />
    </div>
    <Button
      v-if="posts.data.length === 10"
      class="btnReadMore"
      @click="goToNewsPage()"
      >Read more</Button
    >
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src
import swiperSlider from "@/components/organisms/swiperSlider.vue";
import tileLayoutCards from "@/components/organisms/tileLayoutCards.vue";
import config from "../constants/config";
import { mapActions } from "vuex";
const endpoint = config.ENDPOINT;

export default {
  name: "Home",
  components: {
    swiperSlider,
    tileLayoutCards
  },
  data() {
    return {
      topSlider: {
        data: [],
        isLoaded: false
      },
      posts: {
        data: [],
        isLoaded: false
      }
    };
  },
  created() {
    this.set();
  },
  methods: {
    ...mapActions({
      showLoading: "Utility/showLoading",
      hideLoading: "Utility/hideLoading"
    }),
    set() {
      this.showLoading();
      this.requestTopSlider();
      this.requestPosts();
    },
    async requestTopSlider() {
      const url = `${endpoint}top-slider.php`;
      try {
        const result = await axios.get(url);
        result.data.images.forEach(slide => {
          this.topSlider.data.push({
            imgSrc: slide[0]
          });
        });
      } catch (e) {
        console.log("エラー", e);
      } finally {
        this.topSlider.isLoaded = true;
        this.finImageLoad();
      }
    },
    async requestPosts() {
      const url = `${endpoint}posts.php`;
      try {
        const result = await axios.get(url, {
          params: {
            page: 1
          }
        });

        //テスト用の配列を複製して数を増やす
        // for (let i = 0; i < 4; i++) {
        //   result.data.forEach(item => {
        //     this.posts.data.push(item);
        //   });
        // }
        //本番用
        this.posts.data = result.data;
        // もしデータが無ければ画像ロードイベントが発火しないのでここでフラグを戻す
        if (this.posts.data.length === 0) {
          this.finImageLoad();
        }
      } catch (e) {
        console.log("エラー", e);
      }
    },
    finImageLoad() {
      this.posts.isLoaded = true;
      this.checkLoadingStatus();
    },
    checkLoadingStatus() {
      if (!this.topSlider.isLoaded) {
        return false;
      }
      if (!this.posts.isLoaded) {
        return false;
      }
      this.hideLoading();
    },
    goToNewsPage() {
      this.$router.push({ name: "News" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
#home {
  .homeTiles {
    margin-top: setSingleMargin(1);
  }
  .btnReadMore {
    cursor: pointer;
    margin-top: setSingleMargin(2);
  }
}
</style>

<template>
  <div id="theFooter">
    <LargeContainer>
      <div class="logoWrapper">
        <img class="logo" alt="logo" src="../assets/logo.png" />
      </div>
      <div class="footerLinks flex justify-space-around">
        <div
          v-for="(item, idx) in links"
          :key="idx"
          class="link"
          @click="goTo(item)"
          v-wave
        >
          {{ item.label }}
        </div>
      </div>
      <div class="footerText">
        <div class="discription">
          FALCE magazineはY’s C Inc.が運営するフリーマガジン です。
        </div>
      </div>
      <div class="copyright">
        © FALCE magazine
      </div>
    </LargeContainer>
  </div>
</template>

<script>
export default {
  name: "theFooter",
  components: {},
  data() {
    return {
      links: [
        {
          label: "Y’s C",
          link: "https://ysc-inc.com/"
        },
        {
          label: "Model",
          link: "https://models.ysc-inc.com/"
        },
        {
          label: "Contact",
          link: "/contact"
        }
      ]
    };
  },
  methods: {
    goTo(item) {
      if (item.label === "Contact") {
        const routeName = this.$route.name;
        if (routeName === item.label) return;
        this.$router.push({ name: item.label });
      } else {
        const url = item.link;
        window.open(url, "_blank");
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styls/variables.scss";
#theFooter {
  border-top: 3px solid $border-color;
  margin-top: setSingleMargin(2);
  padding: setSingleMargin(2);
  .logoWrapper {
    .logo {
      height: 30px;
    }
  }
  .footerText {
    margin-top: setSingleMargin(2);
    text-align: center;
    .discription {
      font-size: 0.8rem;
    }
  }
  .footerLinks {
    max-width: $content-medium-width;
    margin: setSingleMargin(2) auto 0 auto;
    .link {
      margin: setHorizontalMargin(1);
      cursor: pointer;
      font-size: 0.8rem;
    }
  }
  .copyright {
    margin-top: setSingleMargin(2);
    font-size: 0.8rem;
  }
}
</style>

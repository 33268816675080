<template>
  <div
    class="fmButton"
    :class="{ large: size === 'large', disabled: disabled }"
    @click="onClick()"
    v-wave
  >
    <slot>
      No slot
    </slot>
  </div>
</template>

<script>
export default {
  name: "Button",
  props: {
    size: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
.fmButton {
  font-family: $font-menu;
  font-size: 0.8rem;
  border: none;
  display: inline-block;
  background: $main-color-light;
  color: $main-color;
  border-radius: 5px;
  text-align: center;
  padding: setSingleMargin(0.25) setSingleMargin(1);
  &.large {
    font-size: 1rem;
    padding: setSingleMargin(0.5) setSingleMargin(1.5);
  }
  &.disabled {
    opacity: 0.5;
  }
}
</style>

<template>
  <div class="tileLayoutCards">
    <div v-if="posts.length === 0">
      Loading...
    </div>
    <div id="gridLayoutHome">
      <div
        v-for="(item, idx) in posts"
        :key="idx"
        class="card"
        @click="selectNews(item)"
        v-wave
      >
        <div class="thumbnail">
          <img
            @load="imgLoad"
            :src="getImageSrc(item.thumbnails)"
            :alt="item.title.rendered"
          />
        </div>
        <div class="title">
          <span v-html="sanitizedTitleText(item.title.rendered)"></span>
        </div>
        <div class="body">
          {{ displayShortText(item.content.rendered) }}
        </div>
        <div class="date">
          {{ displayDate(item.modified) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import sanitizeHtml from "sanitize-html";
import MagicGrid from "magic-grid";
export default {
  name: "tileLayoutCards",
  props: {
    posts: {
      type: Array
    }
  },
  data() {
    return {
      imageLoadCount: 0,
      isLoadedAllImages: false,
      magicGrid: null
    };
  },
  computed: {},
  watch: {
    posts() {
      if (this.posts.length === 0) return;
      this.setMagicGrid();
    }
  },
  mounted() {},
  methods: {
    setMagicGrid() {
      this.magicGrid = new MagicGrid({
        static: false,
        container: "#gridLayoutHome", // Required. Can be a class, id, or an HTMLElement.
        items: this.posts.length, // For a grid with 20 items. Required for dynamic content.
        gutter: 15,
        useMin: true,
        animate: true // Optional.
      });

      this.magicGrid.listen();
      // this.magicGrid.positionItems();
    },
    imgLoad() {
      this.imageLoadCount++;
      // すべての画像のロードが完了
      if (this.posts.length === this.imageLoadCount) {
        //再配置
        this.magicGrid.positionItems();
        this.$emit("completeImageLoad");
      }
    },
    displayShortText(text) {
      if (!text) return "";
      text = sanitizeHtml(text);
      // pタグを削除する
      text = text.replace(/<p>/g, "");
      text = text.replace(/<\/p>/g, "");
      text = text.replace(/<br \/>/g, "\n");
      return text.substr(0, 30) + "...";
    },
    displayDate(str) {
      return dayjs(str).format("YYYY/MM/DD H:m");
    },
    sanitizedTitleText(title) {
      return sanitizeHtml(title);
    },
    getImageSrc(thumbnailsObj) {
      if (thumbnailsObj.sizes.medium !== undefined) {
        return thumbnailsObj.sizes.medium.source_url;
      } else {
        return thumbnailsObj.sizes.full.source_url;
      }
    },
    selectNews(item) {
      this.$router.push({
        name: "NewsDetail",
        params: { news_id: item.id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styls/variables.scss";
#gridLayoutHome {
  .card {
    padding: setSingleMargin(1);
    width: 300px;
    cursor: pointer;
    .thumbnail {
      img {
        width: 100%;
      }
    }
    .title {
      margin-top: setSingleMargin(1);
      font-size: 1.2rem;
      font-family: $font-family-header;
    }
    .body {
      margin-top: setSingleMargin(0.5);
      text-align: left;
      font-size: 0.8rem;
      //white-space: pre-wrap;
    }
    .date {
      margin: setSingleMargin(0.5) 0;
      font-size: 0.7rem;
    }
  }
}
</style>

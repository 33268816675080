// initial state
const state = {
  loading: {
    show: false
  }
};

// getters
const getters = {
  getLoading: state => state.loading
};

// actions
const actions = {
  showLoading({ commit }) {
    commit("SET_LOADING", { show: true });
  },
  hideLoading({ commit }) {
    commit("SET_LOADING", { show: false });
  }
};

// mutations
const mutations = {
  /**
   * 端末情報を保持する
   * @param  {Object} state このモジュールのstate
   * @param  {Object} values errorオブジェクト
   */
  SET_LOADING(state, values) {
    Object.keys(values).forEach(key => {
      state.loading[key] = values[key];
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

<template>
  <div class="mediumContainer">
    <slot>
      No slot
    </slot>
  </div>
</template>
<script>
export default {
  name: "container"
};
</script>
<style lang="scss" scoped>
@import "@/styls/variables.scss";
.mediumContainer {
  width: auto;
  max-width: $content-medium-width;
  margin: 0 auto;
  position: relative;
}
</style>
